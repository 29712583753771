import React, { useState } from "react";
import Carousel from "@brainhubeu/react-carousel";

const MiscCarousel = () => {
  const [value, setValue] = useState(0);
  const onChange = (value) => {
    setValue(value);
  };

  const programmingMiscInfo = [
    {
      heading: "Adobe Illustrator",
      info: `Basic knowledge and experience using Adobe Illustrator.`,
      rating: "40%",
      color: "#d1d13d",
      id: 1,
    },
    {
      heading: "Figma",
      info: `
      Good knowledge and experience using the design software Figma.`,
      rating: "75%",
      color: "#a6bb1d",
      id: 2,
    },
    {
      heading: "Git",
      info: `Basic knowledge and experience using version control systems like Github and Gitlab.`,
      rating: "60%",
      color: "#b7c428",
      id: 3,
    },
    {
      heading: "Database",
      info: `
      Good general knowledge and experience working with MySql and Firebase db’s.`,
      rating: "65%",
      color: "#b7c428",
      id: 4,
    },
    {
      heading: "Wordpress",
      info: `Experience using Worpress as a headless cms with Gatsby.js, coupled with Netlify for continous deployment.`,
      rating: "50%",
      color: "#c4c428",
      id: 5,
    },
  ];

  return (
    <>
      <Carousel
        arrows
        value={value}
        onChange={onChange}
        animationSpeed={100}
        minDraggableOffset={0.1}
        itemWidth={409}
        infinite={false}
        draggable={true}
        breakpoints={{
          400: {
            itemWidth: 300,
            arrows: false,
          },
          500: {
            itemWidth: 360,
            arrows: false,
          },
          700: {
            itemWidth: 400,
            arrows: false,
          },
          800: {
            arrows: false,
          },
        }}
      >
        {programmingMiscInfo.map((data, i) => (
          <div className="programming-card" key={data.id}>
            <div className="heading-box">
              <h3 className="scndry-heading">{data.heading}</h3>
              <div
                className="info-graphic"
                style={{
                  color: data.color,
                  border: `solid 3px ${data.color}`,
                }}
              >
                <span className="label-medium">{data.rating}</span>
              </div>
            </div>
            <p className="scndry-text">{data.info}</p>
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default MiscCarousel;
